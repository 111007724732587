<template>
    <div class="g-recaptcha" data-sitekey="6LcezaghAAAAAG3c7YAShTBqbNAZpqqbMQGM6GzM" @isValid='isValid'></div>
</template>

<script>
import axios from "axios"

export default {
     mounted() {
        let externalScript = document.createElement('script')
        externalScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js?render=6LcezaghAAAAAG3c7YAShTBqbNAZpqqbMQGM6GzM')
        document.head.appendChild(externalScript)
    },
    methods:{
        isValid(){
            grecaptcha.ready(function() {
            grecaptcha.execute("6LcezaghAAAAAG3c7YAShTBqbNAZpqqbMQGM6GzM", {action: 'submit'}).then(function(token) {
                console.log(token)
                axios
                    .post("https://www.google.com/recaptcha/api/siteverify", 
                        {
                            secret :'6LcezaghAAAAAP-4lovCmgsDeiBrK3fGwXQbURVa',
                            response: token,
                        },
                        {
                            headers: {
                                "Access-Control-Allow-Origin" :  "*",

                            }
                        }
                        )
                    .then((response) => {
                        console.log(response)
                        
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            });
        });
        }
    }
}
</script>
