var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('b-img',{staticClass:"brand-logo-image",attrs:{"src":_vm.logo,"alt":"soclib logo","width":"120"}})],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"\n                    w-100\n                    d-lg-flex\n                    align-items-center\n                    justify-content-center\n                    px-5\n                "},[_c('b-img',{staticClass:"side-image",attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[(_vm.alertMessages.length > 0)?_c('div',[_c('b-alert',{attrs:{"variant":_vm.alertType,"show":_vm.alertVisibility}},[_c('div',{staticClass:"alert-body"},_vm._l((_vm.alertMessages),function(alertMessage,index){return _c('span',{key:index,staticClass:"d-block",style:(alertMessage.length - 1 == index
                                        ? 'margin-top: 4px'
                                        : '')},[_c('small',{domProps:{"innerHTML":_vm._s(alertMessage)}})])}),0)])],1):_vm._e(),_c('b-card-title',{staticClass:"mb-1 font-weight-bold",attrs:{"title-tag":"h2"}},[_vm._v(" Welcome to soclib ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Please sign-in to your account and start the adventure ")]),_c('validation-observer',{ref:"loginForm"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"login-email"}},[_c('validation-provider',{attrs:{"name":"email","vid":"email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-form-input',{attrs:{"autocomplete":"off","id":"login-email","state":errors.length > 0 ? false : null,"name":"login-email","placeholder":"Enter email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"login-password"}},[_vm._v("Password")]),_c('b-link',{attrs:{"to":{ name: 'auth-forgot-password' }}},[_c('small',[_vm._v("Forgot Password?")])])],1),_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                        var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0
                                            ? 'is-invalid'
                                            : null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"autocomplete":"off","id":"login-password","state":errors.length > 0 ? false : null,"type":_vm.passwordFieldType,"name":"login-password","placeholder":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('b-card-text',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("New on our platform? ")]),_c('b-link',{attrs:{"to":{ name: 'auth-register' }}},[_c('span',[_vm._v(" Create an account")])])],1)],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":"","disabled":_vm.isInProcess}},[(_vm.isInProcess)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),_vm._v(" Sign in ")],1),(_vm.resendVerificationFlag)?_c('b-button',{staticClass:"mt-2",attrs:{"block":"","variant":"outline-secondary"},on:{"click":_vm.resendVerificationHandler}},[_vm._v(" Resend Verification ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }