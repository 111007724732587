<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">
            <!-- Brand logo-->
            <b-link class="brand-logo">
                <b-img
                    :src="logo"
                    class="brand-logo-image"
                    alt="soclib logo"
                    width="120"
                />
            </b-link>
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
                <div
                    class="
                        w-100
                        d-lg-flex
                        align-items-center
                        justify-content-center
                        px-5
                    "
                >
                    <b-img
                        class="side-image"
                        fluid
                        :src="imgUrl"
                        alt="Login V2"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
                <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
                    <!-- alert -->
                    <div v-if="alertMessages.length > 0">
                        <b-alert :variant="alertType" :show="alertVisibility">
                            <div class="alert-body">
                                <span
                                    class="d-block"
                                    v-for="(
                                        alertMessage, index
                                    ) in alertMessages"
                                    :key="index"
                                    :style="
                                        alertMessage.length - 1 == index
                                            ? 'margin-top: 4px'
                                            : ''
                                    "
                                >
                                    <small v-html="alertMessage"></small>
                                </span>
                            </div>
                        </b-alert>
                    </div>
                    <!-- alert -->
                    <b-card-title class="mb-1 font-weight-bold" title-tag="h2">
                        Welcome to soclib
                    </b-card-title>
                    <b-card-text class="mb-2">
                        Please sign-in to your account and start the adventure
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="loginForm">
                        <b-form
                            class="auth-login-form mt-2"
                            @submit.prevent="login"
                        >
                            <!-- email -->
                            <b-form-group label="Email" label-for="login-email">
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    vid="email"
                                    rules="required"
                                >
                                    <b-form-input
                                        autocomplete="off"
                                        id="login-email"
                                        v-model="email"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        name="login-email"
                                        placeholder="Enter email"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>

                            <!-- forgot password -->
                            <b-form-group>
                                <div class="d-flex justify-content-between">
                                    <label for="login-password">Password</label>
                                    <b-link
                                        :to="{ name: 'auth-forgot-password' }"
                                    >
                                        <small>Forgot Password?</small>
                                    </b-link>
                                </div>
                                <validation-provider
                                    #default="{ errors }"
                                    name="Password"
                                    vid="password"
                                    rules="required"
                                >
                                    <b-input-group
                                        class="input-group-merge"
                                        :class="
                                            errors.length > 0
                                                ? 'is-invalid'
                                                : null
                                        "
                                    >
                                        <b-form-input
                                            autocomplete="off"
                                            id="login-password"
                                            v-model="password"
                                            :state="
                                                errors.length > 0 ? false : null
                                            "
                                            class="form-control-merge"
                                            :type="passwordFieldType"
                                            name="login-password"
                                            placeholder="Password"
                                        />
                                        <b-input-group-append is-text>
                                            <feather-icon
                                                class="cursor-pointer"
                                                :icon="passwordToggleIcon"
                                                @click="
                                                    togglePasswordVisibility
                                                "
                                            />
                                        </b-input-group-append>
                                    </b-input-group>
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>

                                <b-card-text class="text-center mt-2">
                                    <span>New on our platform? </span>
                                    <b-link :to="{ name: 'auth-register' }">
                                        <span>&nbsp;Create an account</span>
                                    </b-link>
                                </b-card-text>
                            </b-form-group>

                            <!-- submit buttons -->
                            <b-button
                                type="submit"
                                variant="primary"
                                block
                                :disabled="isInProcess"
                            >
                                <b-spinner v-if="isInProcess" small />
                                Sign in
                            </b-button>
                            <b-button
                                v-if="resendVerificationFlag"
                                class="mt-2"
                                block
                                variant="outline-secondary"
                                @click="resendVerificationHandler"
                            >
                                Resend Verification
                            </b-button>
                        </b-form>
                    </validation-observer>
                </b-col>
            </b-col>
            <!-- /Login-->
        </b-row>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    BSpinner,
    VBTooltip,
} from "bootstrap-vue";
import _ from "lodash";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { heightFade } from "@core/directives/animations";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios"
import Recaptcha from '../../components/recaptcha/Recaptcha.vue' 

export default {
    directives: {
        "height-fade": heightFade,
        "b-tooltip": VBTooltip,
    },
    components: {
        BRow,
        BCol,
        BLink,
        BFormGroup,
        BFormInput,
        BInputGroupAppend,
        BInputGroup,
        BFormCheckbox,
        BCardText,
        BCardTitle,
        BImg,
        BForm,
        BButton,
        BAlert,
        BSpinner,
        ValidationProvider,
        ValidationObserver,
        Recaptcha
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            status: "",
            password: "",
            email: "",

            // validation rules
            required,
            email,

            // alert properties
            alertMessages: [],
            alertType: undefined,
            alertVisibility: false,

            // button loading animation flag
            isInProcess: false,

            // resend button flag
            resendVerificationFlag: false,

            // terms and conditions
            $TermsAndConditions: [],
        };
    },
    mounted() {
        this.getTermsAndConditions();
    },
    computed: {
        imgUrl() {
            return require("@/assets/images/logo/logo_158.png");
        },
        passwordToggleIcon() {
            return this.passwordFieldType === "password"
                ? "EyeIcon"
                : "EyeOffIcon";
        },
        logo() {
            return require("@/assets/images/logo/logo_158.png");
        },
    },
    methods: {
        login() {
            // this.$refs.recaptcha.isValid()
            this.$refs.loginForm.validate().then((success) => {
                if (success) {
                    this.isInProcess = true;
                    useJwt
                        .login({
                            email: this.email,
                            password: this.password,
                        })
                        .then((response) => {
                            const userData = response.data.user_data;
                            useJwt.setToken(response.data.access_token);
                            useJwt.setRefreshToken(response.data.refreshToken);
                            localStorage.setItem(
                                "userData",
                                JSON.stringify(userData)
                            );
                            this.$ability.update(userData.ability);

                            if (response.data.user_data.isVerified) {
                                this.$router
                                    .replace(
                                        getHomeRouteForLoggedInUser(
                                            userData.role
                                        )
                                    )
                                    .then(() => {
                                        this.$toast({
                                            component: ToastificationContent,
                                            position: "top-right",
                                            props: {
                                                title: `Welcome ${
                                                    userData.fullName ||
                                                    userData.username
                                                }`,
                                                icon: "CoffeeIcon",
                                                variant: "success",
                                                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                                            },
                                        });
                                    });
                            }
                        })
                        .catch((error) => {
                            this.alertVisibility = true;
                            this.alertType = "danger";
                            this.alertMessages = _.flatten(
                                Object.values(error.response.data)
                            );
                            this.isInProcess = false;
                        });
                }
            });
        },
        resendVerificationHandler() {
            this.$http.post(
                `${process.env.VUE_APP_URL}/verification-notification`
            );
            this.showAlert(
                "Verification link has been sent to your email account",
                "success"
            );
            setTimeout(() => {
                this.resendVerificationFlag = false;
            }, 5000);
        },
        getTermsAndConditions() {
            this.$http
                .get(`${process.env.VUE_APP_URL}/terms-and-conditions`)
                .then((response) => {
                    console.log(response);
                });
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.side-image {
    width: 400px !important;
}
</style>
